<template>
  <div>
    <div class="skeleton-wrapper">
      <div class="content-left">
        <div class="skeleton-item content-child"></div>
      </div>
      <div>
        <div class="skeleton-item"></div>
      </div>
    </div>
    <div class="skeleton-wrapper">
      <div class="content-left">
        <div class="skeleton-item content-child"></div>
      </div>
      <div>
        <div class="skeleton-item"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JackpotLoader",
  props: {
    count: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style scoped src="./index.css"></style>
